<template>
  <div class="editDialog">
    <el-button type="text" @click="openEdit()">编辑</el-button>

    <!-- 修改角色的对话框 -->
    <el-dialog
            title="编辑挂车"
            :visible.sync="editDialogVisible"
            width="50%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="车牌号" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入车牌号"></el-input>
        </el-form-item>
        <el-form-item label="载重量" prop="deadweight">
          <el-input v-model="ruleForm.deadweight" placeholder="请输入载重量"></el-input>
        </el-form-item>
        <el-form-item v-if="type==1" label="所属运输公司" prop="company">
          <el-select v-model="ruleForm.company" placeholder="请选择运输公司" style="width: 500px;">
            <el-option
                    v-for="item in companyList"
                    :key="item.transportationId"
                    :label="item.transportationName"
                    :value="item.transportationId">
                  </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {EditTrailerRequest } from 'network/transport'
import { getCompanyRequest } from 'network/order'

  export default {
    name: "InvoiceEdit",
    props: {
      row: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        editDialogVisible: false,
        type: '',
        companyList: [],
        ruleForm: {
          name: '',
          deadweight: '',
          company: ''
        },
        rules: {
          rules: {
          name: [
            { required: true, message: '请输入车牌号', trigger: 'blur' },
          ],
          deadweight: [
            { required: true, message: '请输入载重量', trigger: 'blur' },

          ]
        }
        },
      }
    },
    created(){
    this.getCompanyList()
    this.type = localStorage.getItem('type')
  },
    methods: {
      getCompanyList(){
      getCompanyRequest({}).then(res=> {
        let result = res.data
        this.companyList = result.data
      })
    },
      openEdit(){
        this.editDialogVisible = true
        this.ruleForm.name =this.row.trailerNumber
        this.ruleForm.deadweight =this.row.trailerDeadweight
        this.ruleForm.company = this.row.transportationId
      },

      // 监听编辑角色的弹框的关闭事件
      editDialogClose() {
        this.$refs.ruleForm.resetFields();
      },

      // 点击编辑角色弹框的“确认”按钮提交
      editRole() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            EditTrailerRequest({
                trailerId: this.row.trailerId, 
                trailerNumber: this.ruleForm.name,
                trailerDeadweight: this.ruleForm.deadweight,
                transportionId: this.ruleForm.company
              }).then(res=>{
                let result = res.data;
                if (result.code !== 200) {
                  return this.alertMessage('修改失败', 'error');
                }
                this.alertMessage('修改成功', 'success');
                // 隐藏弹窗
                this.editDialogVisible=false
                this.$refs.ruleForm.resetFields();
                this.$emit('plan-list');
              })
          } else {
            return false;
          }
        })
      }
    }
  }
</script>

<style scoped>
.editDialog {
  display: inline-block;
  margin-right: 5px;
}
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
