<!--
 * @Description:
 * @Author:
 * @Date: 2023-03-01 14:51:56
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2023-12-11 09:58:24
-->
<template>
  <el-table highlight-current-row :data="reconciliationList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
    <el-table-column :prop="radio==0?'tractorNumber':radio==1?'trailerNumber':''" label="车牌号" ></el-table-column>
    <el-table-column prop="status" label="状态" ></el-table-column>
    <el-table-column v-if="type==1" prop="transportationName" label="运输公司" >
      <template slot-scope="scope">
        {{ scope.row.transportationName?scope.row.transportationName:name }}
      </template>
    </el-table-column>
    <el-table-column v-if="radio==1" prop="trailerDeadweight" label="载重量（吨）" ></el-table-column>
      <el-table-column prop="createUser" label="创建人" ></el-table-column>
    <el-table-column label="创建时间" prop="createDate" width="170px">
          <template slot-scope="scope">
            {{scope.row.createDate}}
          </template>
        </el-table-column>
    <el-table-column label="操作" width="200px">
      <template slot-scope="scope">
        <invoice-edit v-if="radio==0" :row="scope.row" @plan-list="updateRoleList" />
        <edit-trailer v-if="radio==1" :row="scope.row" @plan-list="updateRoleList" />
        <transport-remove :row="scope.row" :radio="radio" @plan-list="updateRoleList" />
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import EditTrailer from './EditTrailer.vue'
import InvoiceEdit from './TransportEdit'
import TransportRemove from './TransportRemove.vue'

  export default {
    name: "ReconciliationTable",
    props: {
      reconciliationList: {
        type: Array,
        default() {
          return []
        }
      },
      radio: {
        type: String,
        default(){
          return []
        }
      }
    },
    data() {
      return {
        type: '',
        name: '',
      }
    },
    created(){
      this.type = localStorage.getItem('type')
      this.name = localStorage.getItem('enterpriseName')
    },

    components: {
        InvoiceEdit,
        TransportRemove,
        EditTrailer

    },
    methods: {
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList() {
        this.$emit('plan-list');
      }
    }
  }
</script>

<style scoped>

.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}

</style>
