<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-15 15:30:18
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-23 16:47:34
-->
<template>
<div>
    <el-row>
      <el-col>
        <el-button type="primary"  @click="setRightDialogVisible = true">新增牵引车</el-button>
      </el-col>
    </el-row>

  <el-dialog
            :title="addTitle"
            :visible.sync="setRightDialogVisible"
            width="50%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="车牌号" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入车牌号" style="width: 500px"></el-input>
        </el-form-item>
        <el-form-item v-if="type==1" label="所属运输公司" prop="company">
          <el-select v-model="ruleForm.company" placeholder="请选择运输公司" style="width: 500px;">
            <el-option
                    v-for="item in companyList"
                    :key="item.transportationId"
                    :label="item.transportationName"
                    :value="item.transportationId">
                  </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    </div>
</template>
<script>
import { addTractorRequest } from 'network/transport'
import { getCompanyRequest } from 'network/order'

export default {
  name: "TransportAdd",
  props: {
    radio: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      property: 'value',
      setRightDialogVisible: false,
      addTitle: '新增牵引车',
      type: '',
      companyList: [],
      ruleForm: {
          name: '',
          company: ''
        },
        rules: {
          name: [
            { required: true, message: '请输入车牌号', trigger: 'blur' },
          ],
        }
      
            
    };
  },
  created(){
    this.getCompanyList()
    this.type = localStorage.getItem('type')
  },
  methods: {
    getCompanyList(){
      getCompanyRequest({}).then(res=> {
        let result = res.data
        this.companyList = result.data
      })
    },
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            addTractorRequest({
                tractorNumber: this.ruleForm.name,
                enterId: localStorage.getItem('enterpriseId'),
                transportationId: this.ruleForm.company
              }).then(res=>{
                let result = res.data;
                if (result.code !== 200) {
                  return this.alertMessage(result.msg, 'error');
                }
                this.alertMessage('添加成功', 'success');
                // 隐藏弹窗
                this.setRightDialogVisible=false
                this.$refs.ruleForm.resetFields();
                this.$emit('plan-list');
              })
          } else {
            return false;
          }
        });
      },
      resetForm(formName) {
        this.setRightDialogVisible=false
        this.$refs[formName].resetFields();
      }
  }
}
</script>
<style scoped>
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
</style>
