<template>
  <div style="height: 100%;">
    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
      <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>运力管理</template>
      <template v-slot:secondMenu>运力管理</template>
    </breadcrumb-nav>
    <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
        运力管理
      </div>
      <div class="radioBox">
        <el-radio-group v-model="radio" @change="agreeChange" fill = "#fff">
          <el-radio-button label="0" :class="radio==0?'activeRadioButtonBox':'radioButtonBox'">牵引车管理</el-radio-button>
          <el-radio-button label="1" :class="radio==1?'activeRadioButtonBox':'radioButtonBox'">挂车管理</el-radio-button>
          <el-radio-button label="2" :class="radio==2?'activeRadioButtonBox':'radioButtonBox'">司机管理</el-radio-button>
          <el-radio-button label="3" :class="radio==3?'activeRadioButtonBox':'radioButtonBox'">押运员管理</el-radio-button>
        </el-radio-group>
      </div>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="车牌号" v-if="radio==0||radio==1">
          <el-input autosize v-model="queryInfo.query" placeholder="请输入车牌号" @input='view'></el-input>
        </el-form-item>
        <el-form-item :label="radio==2?'司机姓名':'押运员姓名'" v-if="radio==2||radio==3">
          <el-input autosize v-model="queryInfo.query" :placeholder="radio==2?'司机姓名':'押运员姓名'" @input='view'></el-input>
        </el-form-item>
      </el-form>
      <div class="buttonBox">
        <transport-add v-if="radio==0" style="marginRight: 10px"  @plan-list="updateRoleList"/>
        <add-trailer v-if="radio==1" style="marginRight: 10px" @plan-list="updateRoleList"/>
        <add-driver v-if="radio==2" style="marginRight: 10px" @plan-list="updateRoleList"/>
        <add-escort v-if="radio==3" style="marginRight: 10px" @plan-list="updateRoleList"/>
        <el-button type="primary"  @click="ArrangePlan()">导出</el-button>
      </div>
      <!-- table表格区域 -->
      <reconciliation-table v-if="radio==0||radio==1" :reconciliation-list="goodsList" :radio='radio' @plan-list='updateRoleList' />
      <people-table v-if="radio==2||radio==3" :reconciliation-list="goodsList" :radio='radio' @plan-list='updateRoleList' />
      <div style="float: right;">
        <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.pageNum"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total" style="marginBottom:15px;">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
  import BreadcrumbNav from "../common/BreadcrumbNav";
  import ReconciliationTable from './TransportComponents/ListTable';
  import { getTractorListRequest,getTrailerListRequest,getDriverListRequest,getEscortListRequest,exportTractorRequest,exportTrailerRequest,exportDriverRequest,exportEscortRequest } from "network/transport";
  import TransportAdd from './TransportComponents/TransportAdd';
  import AddTrailer from './TransportComponents/AddTrailer';
  import AddDriver from './TransportComponents/AddDriver.vue';
  import AddEscort from './TransportComponents/AddEscort.vue';
import PeopleTable from './TransportComponents/PeopleTable.vue';

  export default {
    name: "List",
    components: {
      BreadcrumbNav,
      ReconciliationTable,
      TransportAdd,
        AddTrailer,
        AddDriver,
        AddEscort,
        PeopleTable,
    },
    data() {
      return {
         radio: '0',
        queryInfo: {
          query: "",
          pageNum: 1,
          pageSize: 10
        },
        formInline: {
          user: '',
          region: ''
        },
        goodsList: [],
        total: 0,
      }
    },
    created() {
      this.getList();
    },
    methods: {
      getList() {
        if(this.radio == 0){
          this.getTractorList()
        }
        if(this.radio == 1){
          this.getTrailerList()
        }
        if(this.radio == 2){
          this.getDriverList()
        }
        if(this.radio == 3){
          this.getEscortList()
        }

      },

      agreeChange(){
        this.queryInfo.pageNum=1
        this.queryInfo.pageSize = 10
        this.getList()
      },
      view(){
        this.queryInfo.pageNum=1
        this.queryInfo.pageSize = 10
        this.getList()
      },
      // 获取牵引车
      getTractorList(){
        getTractorListRequest({
          ...this.queryInfo,
          tractorNumber: this.queryInfo.query,
          enterId: localStorage.getItem('enterpriseId')
          }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取列表数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
      },

      // 获取挂车
      getTrailerList(){
        getTrailerListRequest({
          ...this.queryInfo,
          trailerNumber: this.queryInfo.query,
          enterId: localStorage.getItem('enterpriseId')

          }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取列表数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
      },

      // 获取司机
      getDriverList(){
        getDriverListRequest({
          ...this.queryInfo,
          driverName: this.queryInfo.query,
          enterId: localStorage.getItem('enterpriseId')

          }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取列表数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
      },

      // 获取押运员
      getEscortList(){
        getEscortListRequest({
          ...this.queryInfo,
          escortName: this.queryInfo.query,
          enterId: localStorage.getItem('enterpriseId')
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取列表数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
      },

      //生成下载文件
    downloadCallback(res, fileName){
        const content = res.data;
        const blob = new Blob([content]);
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      },

      ArrangePlan() {
        if(this.radio==0){
          exportTractorRequest({
            characteristic: localStorage.getItem('type')
          }).then(res => {
            var content = res.headers['content-disposition'];
            var name = content && content.split(';')[1].split('filename=')[1];
            var fileName = decodeURIComponent(name)
            this.downloadCallback(res, fileName);
        });
        }
        if(this.radio==1){
          exportTrailerRequest({
            characteristic: localStorage.getItem('type')
          }).then(res => {
            var content = res.headers['content-disposition'];
            var name = content && content.split(';')[1].split('filename=')[1];
            var fileName = decodeURIComponent(name)
            this.downloadCallback(res, fileName);
        });
        }
        if(this.radio==2){
          exportDriverRequest({
            characteristic: localStorage.getItem('type')
          }).then((res) => {
            var content = res.headers['content-disposition'];
            var name = content && content.split(';')[1].split('filename=')[1];
            var fileName = decodeURIComponent(name)
            this.downloadCallback(res, fileName);
        });
        }
        if(this.radio==3){
          exportEscortRequest({
            characteristic: localStorage.getItem('type')
          }).then(res => {
            var content = res.headers['content-disposition'];
            var name = content && content.split(';')[1].split('filename=')[1];
            var fileName = decodeURIComponent(name)
            this.downloadCallback(res, fileName);
        });
        }
      },

      // 每页显示的数据条数发送变化
      handleSizeChange(newSize) {
        this.queryInfo.pageNum = 1
        this.queryInfo.pageSize = newSize;
        this.getList();
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNum = newPage;
        this.getList();
      },
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList(){
        this.getList()
      }
    }
  }
</script>

<style scoped>

.radioBox{
  margin-top: 20px;
  margin-bottom: 15px;
  /* box-shadow: 0px 0 0 0 var(--theme_bg_color) !important; */

}
.radioButtonBox{
  background-color: var(--theme_bg_color);
  box-shadow: 0px 0 0 0 var(--theme_bg_color) !important;
  border-radius: 4px 4px 4px 4px;
}
.el-radio-button :hover{
  color: var(--theme_bg_color);
}
.activeRadioButtonBox :hover{
  color: #fff;
  box-shadow: 0px 0 0 0 var(--theme_bg_color) !important;
}
.activeRadioButtonBox.el-radio-button__orig-radio.el-radio-button__inner{
  box-shadow: 0px !important;
}
.buttonBox{
  display: flex;
}

.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
</style>
