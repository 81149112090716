import {request} from "./request";

// 牵引车列表
export function getTractorListRequest(params) {
  return request({
    url: '/trade/tractor/list',
    params
  })
}
// 添加牵引车
export function addTractorRequest(params){
  return request ({
    url: '/trade/tractor',
    method: 'post',
    data: params
  })
}

// 添加牵引车
export function editTractorRequest(params){
  return request ({
    url: '/trade/tractor',
    method: 'put',
    data: params
  })
}

// 删除牵引车
export function deleteTractorRequest(params){
  return request ({
    url: `/trade/tractor/${params.tractorId}`,
    method: 'delete',
  })
}

// 导出牵引车列表
export function exportTractorRequest(params){
  return request ({
    url: '/trade/tractor/exportExcel',
    method: 'GET',
    params,
    responseType: "blob"
  })
}

// 挂车列表
export function getTrailerListRequest(params) {
  return request({
    url: '/trade/trailer/list',
    params
  })
}

// 添加挂车
export function addTrailerRequest(params){
  return request ({
    url: '/trade/trailer',
    method: 'post',
    data: params
  })
}

// 删除挂车
export function deleteTrailerRequest(params){
  return request ({
    url: `/trade/trailer/${params.trailerId}`,
    method: 'delete',
  })
}

// 编辑挂车
export function EditTrailerRequest(params){
  return request ({
    url: '/trade/trailer',
    method: 'put',
    data: params
  })
}

// 导出挂车列表
export function exportTrailerRequest(params){
  return request ({
    url: '/trade/trailer/exportExcel',
    method: 'GET',
    params,
    responseType: "blob"
  })
}

// 司机列表
export function getDriverListRequest(params) {
  return request({
    url: '/trade/driver/list',
    params
  })
}

// 添加司机
export function addDriverRequest(params){
  return request ({
    url: '/trade/driver',
    method: 'post',
    data: params
  })
}

// 删除司机
export function deleteDriverRequest(params){
  return request ({
    url: `/trade/driver/${params.driverIds}`,
    method: 'delete',
  })
}

// 修改司机
export function editDriverRequest(params){
  return request ({
    url: '/trade/driver',
    method: 'put',
    data: params
  })
}

// 导出司机列表
export function exportDriverRequest(params){
  return request ({
    url: '/trade/driver/exportExcel',
    method: 'GET',
    params,
    responseType: "blob"
  })
}

// 押运员列表
export function getEscortListRequest(params) {
  return request({
    url: '/trade/escort/list',
    params
  })
}
// 添加押运员
export function addEscortRequest(params){
  return request ({
    url: '/trade/escort',
    method: 'post',
    data: params
  })
}

// 删除押运员
export function deleteEscortRequest(params){
  return request ({
    url: `/trade/escort/${params.escortId}`,
    method: 'delete',
  })
}

// 修改押运员
export function editEscortRequest(params){
  return request ({
    url: '/trade/escort',
    method: 'put',
    data: params
  })
}

// 导出押运员列表
export function exportEscortRequest(params){
  return request ({
    url: '/trade/escort/exportExcel',
    method: 'GET',
    params,
    responseType: "blob"
  })
}